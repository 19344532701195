var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-center justify-space-between secondary--text w-100",
          },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("User Settings"),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-tabs",
            { staticClass: "mt-5" },
            _vm._l(_vm.visibleTabs, function (tab) {
              return _c(
                "v-tab",
                _vm._b(
                  { key: tab.tabName, attrs: { to: { name: tab.route } } },
                  "v-tab",
                  _vm._d({}, [tab.e2e, true])
                ),
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v(_vm._s(tab.icon)),
                  ]),
                  _vm._v(" " + _vm._s(tab.tabName) + " "),
                ],
                1
              )
            }),
            1
          ),
          _c("v-divider"),
          _c("router-view"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }